<template>
  <b-modal
    ref="modal-store-excel"
    hide-footer
    :title="titleExcel"
    id="modal-store-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="all">Tất cả các trang</b-form-radio>
            <b-form-radio value="filter">Theo bộ lọc</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button variant="primary" size="sm" @click="exportToExcelStream">
        <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-store-excel')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { cloneDeep } from 'lodash';
export default {
  props: ['param', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      selectedListExcel: 'all',
      apiParams: null,
      titleExcel: 'Xuất excel cửa hàng',
      isDownloading: false,
    };
  },
  components: {
  },
  computed: {},
  created() {},
  watch: {
    $props: {
      handler() {
        this.apiParams = cloneDeep(this.param);
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    checkPermissions,
    exportToExcelStream() {
      this.isDownloading = true;
      const baseUrl = BASE_URL;
      axios
        .get(`${baseUrl}stores/export-excel`, {
          params: {
            ...this.apiParams,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          if (!err.response) {
            // network error
           makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
  },
};
</script>
